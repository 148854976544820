import axios from 'axios';
import utilities from '../utility/utilities';
import config from "../config.json";

const BaseUrl = `${config.lms_url}/api/v2`;

//Continue
const getAllCourses = async () => {

    try {
        const response = await axios.get(`${BaseUrl}/get/all-courses`);
        return response;
    } catch (error) {
        throw error
    }
}

const getAllCoursesAdmin = async () => {
    const token = await utilities.getJwtToken()

    try {
        const response = await axios.get(`${BaseUrl}/admin/get/all-courses`, {
            headers: {
                'authorization': token
            }
        });
        return response;
    } catch (error) {
        throw error
    }
}

const getAllCoursesAdminV2 = async (page, limit, k12 = '0') => {
    const token = await utilities.getJwtToken()

    try {
        const response = await axios.get(`${BaseUrl}/v2/admin/get/all-courses?page=${page ? page : 1}&limit=${limit ? limit : 6}&k12=${k12}`, {
            headers: {
                'authorization': token
            }
        });
        return response;
    } catch (error) {
        throw error
    }
}

const isUser = async (reqToken) => {
    const token = await utilities.getJwtToken()

    try {
        const response = await axios.get(`${BaseUrl}/is-user`, {
            headers: {
                'authorization': `${reqToken ? reqToken : token}`
            }
        })

        return response;
    } catch (error) {
        throw error
    }
}

const getReview = async (reviewId) => {
    const data = {
        reviewId: reviewId
    }
    try {
        const response = await axios.post(`${BaseUrl}/get/review`, data);
        return response;
    } catch (error) {
        throw error
    }
}

const getMyCourses = async () => {
    const token = await utilities.getJwtToken()

    try {
        const response = await axios.get(`${BaseUrl}/v2/get/my-courses`, {
            headers: {
                'Content-Type': 'application/json',
                'authorization': `${token}`
            }
        });
        return response;
    } catch (error) {
        throw error
    }
}

const signIn = async (data) => {
    try {
        const response = await axios.post(`${BaseUrl}/sign-in`, data)
        return response;
    } catch (error) {
        throw error
    }
}

const syncMyCourses = async () => {
    try {

        const token = await utilities.getJwtToken()

        const response = await axios.get(`${BaseUrl}/my-courses/sync`, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response;
    } catch (error) {
        throw error
    }
}

const syncMyCoursesV2 = async () => {
    try {

        const token = await utilities.getJwtToken()

        const response = await axios.get(`${BaseUrl}/v2/my-courses/sync`, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response;
    } catch (error) {
        throw error
    }
}

const getResources = async (topicId) => {
    try {
        const token = await utilities.getJwtToken()

        const response = await axios.get(`${BaseUrl}/resource/${topicId}`, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response;
    } catch (error) {
        throw error
    }
}

const getCourseBySlug = async (slug) => {
    try {
        const token = await utilities.getJwtToken()

        const response = await axios.get(`${BaseUrl}/course/${slug}`, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response
    } catch (error) {
        throw error
    }
}

const getAdminCourseBySlug = async (slug) => {
    try {
        const token = await utilities.getJwtToken()

        const response = await axios.get(`${BaseUrl}/admin/course/${slug}`, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response
    } catch (error) {
        throw error
    }
}

const getAdminSectionsAndTopicsBySlug = async (slug) => {
    try {
        const token = await utilities.getJwtToken()

        const response = await axios.get(`${BaseUrl}/admin/sections-topics/${slug}`, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response
    } catch (error) {
        throw error
    }
}

const getCourseInfoBySlug = async (slug) => {
    try {
        const token = await utilities.getJwtToken()

        const response = await axios.get(`${BaseUrl}/course-info/${slug}`, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response
    } catch (error) {
        throw error
    }
}

const getCategories = async () => {
    try {
        const response = await axios.get(`${BaseUrl}/get/categories`)
        return response
    } catch (error) {
        throw error
    }
}

// aws start
const getPresignedUrl = async (reqData) => {
    try {
        const token = await utilities.getJwtToken()

        const data = {
            fileName: reqData.fileName,
            ContentType: reqData.ContentType
        }


        const responce = await axios.post(`${BaseUrl}/s3/get-presigned-url`, data, {
            headers: {
                'authorization': `${token}`
            }
        })
        return responce
    } catch (error) {
        throw error
    }
}

const sendDataToS3 = async (reqData, onUploadProgress) => {
    try {
        const responce = await axios.put(reqData.url, reqData.file, {
            headers: {
                'Content-Type': reqData.fileType
            },
            onUploadProgress

        })

        return responce

    } catch (error) {
        throw error
    }
}
// aws end

const createAuthor = async (data) => {
    try {
        const token = await utilities.getJwtToken()

        const data1 = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            password: data.password,
            headline: data.headline,
            description: data.description,
            avatar: data.avatarUrl,
            phone: data.phone,
            facebook: data.facebook,
            instagram: data.instagram,
            twitter: data.twitter,
            website: data.website,
            linkedin: data.linkedin,
            categories: JSON.stringify(data.categories),
            highlights: data.highlights,
            avatar_file_id: data.avatar_file_id
        }

        const responce = await axios.post(`${BaseUrl}/create/author`, data1, {
            headers: {
                'authorization': `${token}`
            }
        })
        return responce
    } catch (error) {
        throw error
    }
}

const getAuthors = async () => {
    try {

        const token = await utilities.getJwtToken()

        const response = await axios.get(`${BaseUrl}/authors`, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response;
    } catch (error) {
        throw error
    }
}

const getLevels = async () => {
    try {
        const response = await axios.get(`${BaseUrl}/levels`)
        return response;
    } catch (error) {
        throw error
    }
}

const getLanguages = async () => {
    try {
        const response = await axios.get(`${BaseUrl}/languages`)
        return response;
    } catch (error) {
        throw error
    }
}

const createCourse = async (data) => {
    try {
        const token = await utilities.getJwtToken()

        const response = await axios.post(`${BaseUrl}/course/upload`, data, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response;
    } catch (error) {
        throw error
    }
}

const updateCourse = async (data) => {
    try {
        const token = await utilities.getJwtToken()

        const response = await axios.put(`${BaseUrl}/course/${data.slug}`, data, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response;
    } catch (error) {
        throw error
    }
}

const createSection = async (reqData) => {
    try {
        const token = await utilities.getJwtToken()

        const data = {
            courseId: reqData.courseId,
            name: reqData.name
        }

        const response = await axios.post(`${BaseUrl}/section/upload`, data, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response;
    } catch (error) {
        throw error
    }
}

const createTopic = async (reqData) => {
    try {
        const token = await utilities.getJwtToken()

        const data = {
            sectionId: reqData.sectionId,
            name: reqData.topicName,
            fileId: reqData.fileId
        }

        const response = await axios.post(`${BaseUrl}/topic/upload`, data, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response;
    } catch (error) {
        throw error
    }
}

const updateTopicVideo = async (topicId, fileId) => {
    try {
        const token = await utilities.getJwtToken()

        const data = {
            topicId: topicId,
            fileId: fileId
        }


        const response = await axios.put(`${BaseUrl}/topic/video`, data, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response;
    } catch (error) {
        throw error
    }
}

const createResourse = async (reqData) => {
    try {
        const token = await utilities.getJwtToken()

        const data = {
            topicId: reqData.topicId,
            fileId: reqData.fileId
        }

        const response = await axios.post(`${BaseUrl}/resource/upload`, data, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response;
    } catch (error) {
        throw error
    }
}

const deleteSection = async (reqData) => {
    try {
        const token = await utilities.getJwtToken()

        const response = await axios.get(`${BaseUrl}/section/delete/${reqData.sectionId}`, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response;
    } catch (error) {
        throw error
    }
}

const deleteResource = async (reqData) => {
    try {
        const token = await utilities.getJwtToken()


        const response = await axios.get(`${BaseUrl}/resource/delete/${reqData.resourceId}`, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response;
    } catch (error) {
        throw error
    }
}

const deleteTopic = async (reqData) => {
    try {
        const token = await utilities.getJwtToken()

        const response = await axios.get(`${BaseUrl}/topic/delete/${reqData.topicId}`, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response;
    } catch (error) {
        throw error
    }
}

const fileCreate = async (reqData) => {
    try {
        const token = await utilities.getJwtToken()

        const data = {
            name: reqData.name,
            size: reqData.size,
            type: reqData.type,
            duration: reqData.duration
        }
        const response = await axios.post(`${BaseUrl}/file/create`, data, {
            headers: {
                'authorization': `${token}`
            }
        })

        return response;
    } catch (error) {
        throw error
    }
}

const updateStorageKey = async (reqData) => {
    try {
        const token = await utilities.getJwtToken()

        const data = {
            fileId: reqData.fileId,
            storageKey: reqData.storageKey,
        }
        const response = await axios.put(`${BaseUrl}/update/storage-key`, data, {
            headers: {
                'authorization': `${token}`
            }
        })

        return response;
    } catch (error) {
        throw error
    }
}

const readFileUrl = async (reqData) => {
    try {
        const token = await utilities.getJwtToken()

        const data = {
            fileId: reqData.fileId
        }

        const response = await axios.post(`${BaseUrl}/s3/get-presigned-url/get-object`, data, {
            headers: {
                'authorization': `${token}`
            }
        })

        return response
    } catch (error) {
        throw error
    }
}

const getSearchResult = async (searchQuery) => {
    const data = {
        searchQuery: searchQuery
    }
    try {
        const responce = await axios.post(`${BaseUrl}/get/search`, data);
        return responce
    } catch (error) {
        throw error
    }
}

const userTrackHandle = async (topicId, isWatched) => {
    const token = await utilities.getJwtToken()
    const data = {
        topicId,
        isWatched
    }
    try {
        const responce = await axios.post(`${BaseUrl}/track/make`, data, {
            headers: {
                'authorization': `${token}`
            }
        });
        return responce
    } catch (error) {
        throw error
    }
}

const courseStatusHandle = async (slug) => {
    const token = await utilities.getJwtToken()

    try {
        const response = await axios.put(`${BaseUrl}/course/update-status/${slug}`, {}, {
            headers: {
                'authorization': `${token}`
            }
        });
        return response
    } catch (error) {
        throw error
    }
}

const deleteCourseBySlug = async (slug) => {
    try {
        const token = await utilities.getJwtToken()

        const response = await axios.delete(`${BaseUrl}/admin/course/${slug}`, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response
    } catch (error) {
        throw error
    }
}

const updateDuration = async (courseId, completion) => {
    try {
        const token = await utilities.getJwtToken()

        const data = {
            courseId: courseId,
            completion: completion
        }

        const response = await axios.put(`${BaseUrl}/my-course/duration`, data, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response
    } catch (error) {
        throw error
    }
}

const updateSection = async (id, name) => {
    try {
        const token = await utilities.getJwtToken()

        const data = {
            id: id,
            name: name
        }

        const response = await axios.put(`${BaseUrl}/section`, data, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response
    } catch (error) {
        throw error
    }
}

const updateTopic = async (id, name) => {
    try {
        const token = await utilities.getJwtToken()

        const data = {
            id: id,
            name: name
        }

        const response = await axios.put(`${BaseUrl}/topic`, data, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response
    } catch (error) {
        throw error
    }
}

const getAdminAuthors = async (page = 1) => {
    try {
        const token = await utilities.getJwtToken();

        const response = await axios.get(`${BaseUrl}/admin/authors?page=${page}`, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response
    } catch (e) {
        throw e;
    }
}

const deleteAdminAuthor = async (id) => {
    try {
        const token = await utilities.getJwtToken();

        const response = await axios.delete(`${BaseUrl}/admin/author/${id}`, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response
    } catch (e) {
        throw e;
    }
}

const getAdminAuthor = async (id) => {
    try {
        const token = await utilities.getJwtToken();

        const response = await axios.get(`${BaseUrl}/admin/author/${id}`, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response
    } catch (e) {
        throw e;
    }
}

const updateAuthor = async (data) => {
    try {
        const token = await utilities.getJwtToken();

        const response = await axios.put(`${BaseUrl}/admin/author/${data.id}`, data, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response;
    } catch (e) {
        throw e;
    }
}

const isUserHaveCourse = async (slug) => {
    try {
        const token = await utilities.getJwtToken();

        const response = await axios.put(`${BaseUrl}/check-user-has-course/${slug}`, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response;
    } catch (e) {
        throw e;
    }
}

const getCertificate = async (courseId) => {
    try {
        const token = await utilities.getJwtToken();

        const data = {
            courseId: courseId
        }

        const response = await axios.post(`${BaseUrl}/certificate/generate`, data, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response;
    } catch (e) {
        throw e;
    }
}

const searchCategories = async (search) => {
    try {
        const token = await utilities.getJwtToken();

        const response = await axios.get(`${BaseUrl}/category?search=${search}`, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response;
    } catch (e) {
        throw e;
    }
}

const createCategory = async (name) => {
    try {
        const token = await utilities.getJwtToken();

        const data = {
            name: name
        }

        const response = await axios.post(`${BaseUrl}/v2/category/create`, data, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response;
    } catch (e) {
        throw e;
    }
}

const updateCategory = async (id, name) => {
    try {
        const token = await utilities.getJwtToken();

        const data = {
            id: id,
            name: name
        }

        const response = await axios.put(`${BaseUrl}/category`, data, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response;
    } catch (e) {
        throw e;
    }
}

const createSecret = async (token) => {
    try {

        const response = await axios.get(`${BaseUrl}/app-write/create-secret`, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response;
    } catch (e) {
        throw e;
    }
}

const createChapter = async (name, slug) => {
    try {
        const token = await utilities.getJwtToken();

        const data = {
            name: name,
            slug: slug
        }

        const response = await axios.post(`${BaseUrl}/k12`, data, {
            headers: {
                'authorization': `${token}`
            }
        })
        return response;
    } catch (e) {
        throw e;
    }
}

const getK12 = async (courseName) => {
    try {
        const result = await axios.get(`${BaseUrl}/k12/${courseName}`)
        return result;
    } catch (e) {
        console.error(e)
        throw e;
    }
}


export default {
    getAllCourses, getReview, getMyCourses, getSearchResult, signIn,
    isUser, syncMyCourses, getResources, getCourseBySlug, getPresignedUrl, sendDataToS3,
    getCategories, createAuthor, getAuthors, getLevels, getLanguages, createCourse,
    createSection, createTopic, createResourse, deleteSection, deleteTopic, deleteResource,
    updateCourse, fileCreate, updateStorageKey, readFileUrl, getCourseInfoBySlug, userTrackHandle,
    courseStatusHandle, getAllCoursesAdmin, getAllCoursesAdminV2, deleteCourseBySlug, getAdminCourseBySlug,
    getAdminSectionsAndTopicsBySlug, updateTopicVideo, updateDuration, syncMyCoursesV2, updateSection,
    updateTopic, getAdminAuthors, deleteAdminAuthor, getAdminAuthor, updateAuthor,
    isUserHaveCourse, getCertificate, searchCategories, createCategory, updateCategory, createSecret,
    createChapter, getK12
}
