import React, { useEffect, useState } from 'react'
import personImage from "../../assets/images/person.jpg"
import StartRating from '../../components/globle/startRating'
import Button from '../../components/globle/button'
import { Link, useNavigate } from 'react-router-dom'
import api from "../../services/api";

const Authors = () => {
    const navigation = useNavigate()
    const [authors, setAuthors] = useState([]);
    const [page, setPage] = useState(1)

    const fetchAuthors = async (page = 1) => {
        try {
            const response = await api.getAdminAuthors(page);

            const authorsResponse = response?.data?.data || [];

            setAuthors(authorsResponse)
        } catch (e) {
            console.error(e?.response?.data?.message || "Not able to fetch authors")
        }
    }

    const deleteAuthorHandle = async (id) => {
        try {
            const userConfirmed = window.confirm("Are you sure you want to proceed?");

            if (userConfirmed) {
                const response = await api.deleteAdminAuthor(id);

                fetchAuthors()
            }


        } catch (e) {
            console.error(e)
        }
    }


    const onNextClickHandle = () => {
        fetchAuthors(page + 1);
        setPage(prevState => prevState + 1);
    }
    const onPreviousClickHandle = () => {
        if (page > 1) {
            fetchAuthors(page - 1);
            setPage(prevState => prevState - 1);
        }
    }

    useEffect(() => {
        fetchAuthors(page);
    }, [])


    return (
        <div
            className='top-cont'
        >
            <table
                width={"100%"}
                style={{
                    marginTop: "50px"
                }}
            >
                <thead>
                    <tr

                    >
                        <td>
                            <p
                                className={'text-white'}
                                style={{
                                    margin: "0",
                                    fontFamily: "semiBold",
                                    color: "#464E5F",
                                    padding: "12px 24px",
                                    borderRadius: "6px"
                                }}
                            >Authors</p></td>

                        <td
                            style={{
                                padding: "12px 24px",
                            }}
                        >
                            <p className={'text-white'}
                                style={{
                                    margin: "0",
                                    fontFamily: "semiBold",
                                    color: "#464E5F",
                                    borderRadius: "6px"
                                }}
                            >Edit</p></td>

                    </tr>
                </thead>
                <tbody>
                    {
                        authors.length !== 0
                            ?
                            authors.map((item, index) => {
                                return (
                                    <tr
                                        key={index}
                                        style={{
                                            paddingTop: "20px"
                                        }}
                                    >
                                        <td>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "15px",
                                                    padding: "12px 24px",

                                                }}
                                            >
                                                <img
                                                    style={{
                                                        width: "50px",
                                                        height: "50px",
                                                        borderRadius: "6px",
                                                        objectFit: "cover"
                                                    }}
                                                    src={item?.avatar || ""} />
                                                <p
                                                    className={'text-white'}
                                                    style={{
                                                        fontFamily: "semiBold",
                                                        fontSize: "16px",
                                                        margin: "0"
                                                    }}
                                                >{(item?.first_name || " ") + " " + (item?.last_name || " ")}</p>
                                            </div>
                                        </td>

                                        <td>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "15px",
                                                    padding: "12px 24px",

                                                }}
                                            >
                                                <Link to={`/admin/author/edit/${item?.id || null}`}>
                                                    <Button
                                                        className={'btn-dark-500'}
                                                        buttonStyle={{
                                                            height: "34px"
                                                        }}
                                                        title={"Edit"} />
                                                </Link>
                                                <Button
                                                    className={'btn-dark-500'}
                                                    onClick={() => deleteAuthorHandle(item?.id || null)}
                                                    buttonStyle={{
                                                        height: "34px"
                                                    }}
                                                    title={"Delete"} />
                                            </div>
                                        </td>


                                    </tr>
                                )
                            })
                            :
                            <></>
                    }

                </tbody>
            </table>
            <button onClick={onPreviousClickHandle}>Previous</button>
            <button >{page}</button>
            <button onClick={onNextClickHandle}>Next</button>
        </div>
    )
}

export default Authors
