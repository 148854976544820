import React, { forwardRef, useState } from 'react'
import DownArrow from '../../assets/icons/downArrow'
import CloseIcon from '../../assets/icons/closeIcon'
import theme from '../../theme/theme'
import api from '../../services/api'
import LoadingAnimation from './loadingAnimation'
import { filesize } from "filesize";



const CrudDropdown = ({ buttonStyle, resources, getCourseRes }) => {
    const [isModelShow, setIsModelShow] = useState(false)
    const [imageUploadingLoading, setImageUploadingLoading] = useState(false)
    const [avatarUrl, setAvatarUrl] = useState("")
    const [fileId, setFileId] = useState(0)


    const avatarHandle = async (event) => {

        try {
            setImageUploadingLoading(true)
            const file = event.target.files[0];

            // create file start
            let fData = {
                name: file.name,
                size: file.size,
                type: file.type,
            }

            const createFile = await api.fileCreate(fData);

            if (createFile && createFile.data && createFile.data.data && createFile.data.data.id) {
                setFileId(createFile.data.data.id)
                const data = {
                    fileName: file.name,
                    ContentType: file.type
                }

                const response = await api.getPresignedUrl(data)
                const presignedUrl = response.data.data;
                const fileData = {
                    url: presignedUrl,
                    fileType: file.type,
                    file: file
                }

                const sendDataResponse = await api.sendDataToS3(fileData);
                if (sendDataResponse) {

                    const imageUrl = presignedUrl.split("?")[0]

                    // update key start
                    const videoUrl = imageUrl;
                    const urlObject = new URL(videoUrl);
                    let pathname = urlObject.pathname;
                    let storageKey = "";
                    for (let i = 1; i < pathname.length; i++) {
                        storageKey = storageKey + pathname[i]
                    }
                    const actualNameKey = decodeURIComponent(storageKey);

                    const njernData = {
                        fileId: createFile.data.data.id,
                        storageKey: actualNameKey
                    }
                    const updateKey = await api.updateStorageKey(njernData);
                    createResourseHandle(createFile.data.data.id)

                    // update key end

                }


            }
            // create file end
            setImageUploadingLoading(false)

        } catch (e) {
            alert("Unable to upload resourse");
            setImageUploadingLoading(false)
        }


    }

    const createResourseHandle = async (fileId) => {

        try {
            const data = {
                topicId: resources.id,
                fileId: fileId
            }

            const response = await api.createResourse(data)
            getCourseRes()
        } catch (e) {
            alert(e?.response?.data?.message || "Unable to create resource")
        }
    }

    const deleteResource = async (resourceId) => {

        try {
            const userConfirmed = window.confirm("Are you sure you want to proceed?");
            if (userConfirmed) {
                const data = {
                    resourceId: resourceId
                }
                const response = await api.deleteResource(data)
                getCourseRes()
            }

        } catch (e) {
            alert("Unable to delete resource")
        }

    }


    return (
        <div
            onMouseOver={() => setIsModelShow(true)}
            onMouseLeave={() => setIsModelShow(false)}
            style={{
                position: "relative",

            }}
        >
            {/* button */}
            <div
                className={'bg-dark-400'}
                style={{
                    height: "40px",
                    border: "0",
                    borderRadius: "12px",
                    background: "#F6F6F6",
                    padding: "0 20px",
                    ...buttonStyle,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                    cursor: "pointer"
                }}
            >
                <p style={{
                    width: "100px",
                    overflow: "hidden",
                    fontFamily: "semiBold",
                    fontSize: "12px",
                    margin: "0",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                }}
                >{resources && resources.resourses && resources.resourses.length ? `${resources.resourses.length}` : "Add"} resources</p>
                <DownArrow className={'filter-invert-100'} />
            </div>
            {/* drop down */}
            <div
                className={'border-2-s-dark-500 bg-dark-400'}
                style={{
                    zIndex: "100",
                    position: "absolute",
                    top: "40px",
                    right: "0px",
                    width: "250px",
                    maxHeight: "500px",
                    backgroundColor: "white",
                    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.16)",
                    borderRadius: "12px",
                    overflow: "hidden",
                    padding: "20px",
                    cursor: "pointer",
                    display: isModelShow ? "flex" : "none",
                    flexDirection: "column",
                    gap: "10px"
                }}
            >
                {
                    resources && resources.resourses !== 0 ?
                        resources.resourses.map((i, index) => {
                            return (
                                <div
                                    className={'bg-dark-500 text-white'}
                                    key={index}
                                    style={{
                                        width: "100%",
                                        padding: "14px",
                                        background: "#F6F6F6",
                                        borderRadius: "12px",
                                        display: "flex",
                                        alignItems: 'center',
                                        justifyContent: "space-between",
                                        cursor: "pointer"
                                    }}
                                >
                                    <p
                                        title={i?.file?.name}
                                        style={{
                                            width: "100px",
                                            overflow: "hidden",
                                            fontFamily: "semiBold",
                                            fontSize: "12px",
                                            margin: "0",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                        }}
                                    >{i?.file?.name}</p>
                                    {
                                        i?.file?.size &&
                                        <p
                                            className='text-primary'
                                            style={{
                                                fontSize: '12px',
                                                fontWeight: 600,
                                                textWrap: 'nowrap'
                                            }}
                                        >{filesize(i?.file?.size)}</p>
                                    }
                                    <CloseIcon className={'filter-invert-100'} onClick={() => deleteResource(i.id)} />
                                </div>
                            )
                        })
                        :
                        <></>
                }


                <input
                    className={imageUploadingLoading ? "d-none" : ""}
                    accept=".doc, .docx, .xls, .xlsx, .pdf "
                    onChange={avatarHandle}
                    type='file' />
                {
                    imageUploadingLoading ?

                        <LoadingAnimation
                            style={{
                                width: "50px",
                                height: "50px",
                            }}
                        />
                        : <></>
                }

            </div>
        </div>
    )
}

export default CrudDropdown
